import * as React from 'react';

import { slugify } from '@blockworks/platform/utils';
import { ImageWithFallback } from '@blockworks/ui/image-with-fallback';
import { Link } from '@blockworks/ui/legacy/link';

import ByLine from '@/components/pages/frontpage/components/feature/middle/by-line';
import type { ArticleType } from '@/types/article-type';
import decodeEntities from '@/utils/functions/string/decode-entities';
import useBlurhashPlaceholder from '@/utils/hooks/use-blurhash-placeholder';

type SubFeatureProps = {
    article: ArticleType;
    showImage: boolean;
};

const SubFeaturedArticleCard = (props: SubFeatureProps) => {
    const { article, showImage } = props;
    const { excerpt, slug, primaryCategory, title, authors, publishedAt, blurhash } = article;

    const placeholder = useBlurhashPlaceholder(blurhash);

    return (
        <div className="justify-start items-center flex-grow gap-2 w-full">
            {showImage && (
                <Link href={`/news/${slug}`} className="cursor-pointer">
                    <ImageWithFallback
                        {...placeholder}
                        src={article.imageUrl}
                        fallbackSrc="/images/Blockworks_Meta_Logo.png"
                        width={560}
                        height={315}
                        quality={20}
                        decoding="async"
                        className="w-full h-auto mb-2"
                        alt="article-image"
                    />
                </Link>
            )}
            <div className="gap-1">
                <p className="flex gap-2 flex-grow">
                    {primaryCategory.toLowerCase() !== 'sponsored' && (
                        <Link
                            href={`/category/${slugify(decodeEntities(primaryCategory))}`}
                            className="text-primary text-xs uppercase"
                        >
                            {decodeEntities(primaryCategory)}
                        </Link>
                    )}
                    {article.categories.includes('Sponsored') && (
                        <Link href={`/category/sponsored`} className="text-blue-500 text-xs uppercase">
                            Sponsored
                        </Link>
                    )}
                </p>
            </div>
            <div className="mt-1 gap-2.5">
                <Link
                    href={`/news/${slug}`}
                    className="flex-grow text-lg md:text-xl xl:text-2xl font-headline hover:text-primary"
                >
                    {title}
                </Link>
            </div>
            <div className="mt-1 gap-2.5 text-gray-500">{excerpt}</div>
            <ByLine authors={authors} publishedAt={publishedAt} className="mt-2 text-xs" />
        </div>
    );
};

export default SubFeaturedArticleCard;
